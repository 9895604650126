import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const SendContact = createAsyncThunk(
  "Contact/SendContact",
  async (resD, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .post(`${process.env.REACT_APP_BACKEND_API}/contacts`, resD, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProduct = createAsyncThunk(
  "Contact/getProduct",
  async (resD, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}/products/${resD}`, {
          headers: {
            Authorization: `Bearer  ${localStorage.getItem("ut")}`,
          },
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getProductSummery = createAsyncThunk(
  "Contact/getProductSummery",
  async (resD, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}/summary${resD}`, {
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const AddToCartApi = createAsyncThunk(
  "Contact/AddToCartApi",
  async (resD, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .post(`${process.env.REACT_APP_BACKEND_API}/card`, resD, {
          headers: {
            Authorization: `Bearer  ${localStorage.getItem("ut")}`,
          },
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const CalcShipment = createAsyncThunk(
  "Contact/CalcShipment",
  async (resD, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .post(`${process.env.REACT_APP_BACKEND_API}/shipments/calculate`, {
          country_id : resD.id, 
          amount : resD.amount
        }, {
          headers: {
            Authorization: `Bearer  ${localStorage.getItem("ut")}`,
          },
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const GetToCart = createAsyncThunk(
  "Contact/GetToCart",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}/card`, {
          headers: {
            Authorization: `Bearer  ${localStorage.getItem("ut")}`,
          },
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const DeleteFromcart = createAsyncThunk(
  "Contact/DeleteFromcart",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .delete(`${process.env.REACT_APP_BACKEND_API}/card/${id}`, {
          headers: {
            Authorization: `Bearer  ${localStorage.getItem("ut")}`,
          },
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const GetCpupon = createAsyncThunk(
  "Contact/GetCpupon",
  async (resD, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}/coupon`, {
          headers: {
            Authorization: `Bearer  ${localStorage.getItem("ut")}`,
          },
          params: {
            name: resD,
          },
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const AddPayment = createAsyncThunk(
  "Contact/AddPayment",
  async (resD, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .post(`${process.env.REACT_APP_BACKEND_API}/payment`, resD, {
          headers: {
            Authorization: `Bearer  ${localStorage.getItem("ut")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const UpdateProfile = createAsyncThunk(
  "Contact/UpdateProfile",
  async (resD, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}/users/${localStorage.getItem(
            "ui_Ap"
          )}`,
          resD,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorization: `Bearer  ${localStorage.getItem("ut")}`,
            },
          }
        )
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const UpdateDefult = createAsyncThunk(
  "Contact/UpdateDefult",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .put(
          `${process.env.REACT_APP_BACKEND_API}/addresses/${id}`,
          {
            is_default: 1,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer  ${localStorage.getItem("ut")}`,
            },
          }
        )
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const DeleteAddress = createAsyncThunk(
  "Contact/DeleteAddress",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .delete(
          `${process.env.REACT_APP_BACKEND_API}/addresses/${id}`,

          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer  ${localStorage.getItem("ut")}`,
            },
          }
        )
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
const ContactSlice = createSlice({
  name: "Contact",
  initialState: {
    isEmployeesLoading: false,
    error: null,
    empolyees: null,
    productArr: null,
    summeryArr: null,
    CartArr: null,
    country_id: null,
    TotalPrice: 0,
    shipment_price: 0,
    total_amount: 0,

  },

  extraReducers: {
    [getProduct.pending]: (state, action) => {
      state.isEmployeesLoading = true;
    },
    [getProduct.fulfilled]: (state, action) => {
      state.productArr = action.payload.data;
      state.isEmployeesLoading = false;
    },
    // getProductSummery
    [getProductSummery.pending]: (state, action) => {
      state.isEmployeesLoading = true;
    },
    [getProductSummery.fulfilled]: (state, action) => {
      state.summeryArr = action.payload.data;
      state.isEmployeesLoading = false;
    },
    // GetToCart
    [GetToCart.pending]: (state, action) => {
      state.isEmployeesLoading = true;
    },
    [GetToCart.fulfilled]: (state, action) => {
      state.CartArr = action.payload.data;
      state.TotalPrice = action.payload.data.reduce(
        (acc, cur) => parseFloat(acc) + parseFloat(cur.price),
        0
      );
      state.isEmployeesLoading = false;
    },

    [CalcShipment.pending]: (state, action) => {
      state.shipment_price = true;
      state.country_id = true;
      state.total_amount = true;
    },
    [CalcShipment.fulfilled]: (state, action) => {
       state.shipment_price = action.payload.data.shipment_price;
       state.country_id = action.payload.data.country_id;
       state.total_amount = action.payload.data.total_amount;
 
    },

    [GetCpupon.fulfilled]: (state, action) => {
      if (action.payload.data) {
        state.TotalPrice =
          state.TotalPrice - (state.TotalPrice * action.payload.data) / 100;
      }
      state.isEmployeesLoading = false;
    },
  },
});

export default ContactSlice.reducer;
