import React from 'react';

const TermsConditions = () => {
  return (
    <div>
      <p>
        These Terms of Sale set out the terms under which Goods are sold by Us to business customers through this website, www.aprint.com ("Our Site”). Please read these Terms of Sale carefully and ensure that you understand them before ordering any Goods from Our Site. You will be required to read and accept these Terms of Sale when ordering Goods. If you do not agree to comply with and be bound by these Terms of Sale, you will not be able to order Goods through Our Site. These Terms of Sale, as well as any and all Contracts are in the English language only.
      </p>
      <h2>1. Definitions and Interpretation</h2>
      <p>
        In these Terms of Sale, unless the context otherwise requires, the following expressions have the following meanings:
        <ul>
          <li><strong>“Contract”</strong> means a contract for the purchase and sale of Goods, as explained in Clause 7;</li>
          <li><strong>“Goods”</strong> means the goods sold by Us through Our Site;</li>
          <li><strong>“Order”</strong> means your order for Goods;</li>
          <li><strong>“Order Confirmation”</strong> means Our acceptance and confirmation of your Order;</li>
          <li><strong>“Order Number”</strong> means the reference number for your Order; and</li>
          <li><strong>“We/Us/Our”</strong> means Banner World Ltd, a company registered in England under 08078716, whose registered address is 29 Whitegate Drive, Blackpool, Lancashire, FY3 9AA and whose main trading address is C. la Perdiz, 28, 11300 La Línea de la Concepción, Cádiz, España.</li>
        </ul>
      </p>
      <h2>2. Information About Us</h2>
      <p>
        APrint trading Name of MEGAIMPRESIONES DEL SUR S.L, a company registered in Spain under B72180086, whose registered address and whose main trading address is C. la Perdiz, 28, 11300 La Línea de la Concepción, Cádiz, España
      </p>
      <h2>3. Access to and Use of Our Site</h2>
      <ol>
        <li>Access to Our Site is free of charge.</li>
        <li>It is your responsibility to make any and all arrangements necessary in order to access Our Site.</li>
        <li>Access to Our Site is provided “as is” and on an “as available” basis. We may alter, suspend or discontinue Our Site (or any part of it) at any time and without notice. We will not be liable to you in any way if Our Site (or any part of it) is unavailable at any time and for any period.</li>
        <li>Use of Our Site is subject to Our Website Terms of Use. Please ensure that you have read them carefully and that you understand them.</li>
      </ol>
      {/* Continuing with the rest of the text */}
    </div>
  );
};

export default TermsConditions;
