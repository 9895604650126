import React, { useState, useEffect } from "react";
import styles from "../styles/cart/cart.module.css";
import Helmet from "../components/Helmet";
import axios from "axios";
import { Row, Col, Container } from "react-bootstrap";
import ServiceItem from "../components/ServiceItem";
import styles1 from "../styles/home/home.module.css";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ModalMe from "../components/ModalMe";
import {
  CalcShipment,
  DeleteFromcart,
  GetCpupon,
  GetToCart,
} from "../store/ContactSlice";

const Cart = ({ lang }) => {
  const [cartItems, setCartItems] = useState([]);
  const [data, setData] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [paymentSuccessMessage, setPaymentSuccessMessage] = useState("");
  const [paymentModal, setPaymentModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let dataIds = [...data];
  const cartDataClone = [...cartItems];

  cartDataClone.forEach((item, index) => {
    dataIds.push({ product_id: item.id });
  });
  dataIds = dataIds.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.product_id === value.product_id)
  );

  let initialPrices = [];
  cartItems.forEach((item) => {
    initialPrices.push(0);
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    navigate("/payment");
    e.stopPropagation();
  };

  const handleDelete = async (pro_id) => {
    setDeleteLoading(true);
    dispatch(DeleteFromcart(pro_id))
      .unwrap()
      .then(() => {
        dispatch(GetToCart());
      });
  };

  const { CartArr, TotalPrice , shipment_price , total_amount} = useSelector((state) => state.ContactSlice);
  useEffect(() => {
    if (!CartArr) {
      dispatch(GetToCart());
    }
  }, [dispatch, CartArr]);

  const [discount, setCartDiscount] = useState("");


  const [DisplayShipment, setDisplayShipment] = useState(false)
  const [Countries, setCountries] = useState([])
  const [ShipingPrice , setShipingPrice] = useState()
  const getCountries= async()=>{
    try {
      let data = await axios.get(process.env.REACT_APP_BACKEND_API + `/countries`)
      setCountries(data?.data.data);
    } catch (error) {
      console.log(error.message);
    }
  }
  const showShipment =(e)=>{
    e.preventDefault()
    setDisplayShipment(true)
    getCountries()
  }

  const handleCountryChange= async (e)=>{
    try {
      let payload ={
        id : e.target.value, 
        amount : TotalPrice
      }
      dispatch(CalcShipment(payload))        
      .unwrap()
      .then(() => {
        setShipingPrice(total_amount)
      });
    } catch (error) {
      
    }
  }
  return (
    <Helmet title={lang === "ar" ? "عربه التسوق" : "cart"}>
      <ModalMe
        show={paymentModal}
        lang={lang}
        onHide={() => setPaymentModal(false)}
        body={paymentSuccessMessage}
      />
      <section className={`${styles.cartSection}`}>
        <Container>
          <h3 className={styles.heading}>Cart</h3>
          <form action="" onSubmit={showShipment}>
            <Row>
              <Col lg="12" xl="12">
                <table className="border-0 mt-4">
                  <thead className={lang === "ar" ? `text-end` : `text-start`}>
                    <tr className="border-0">
                      <th className="">Products Added</th>
                      <th>QTY</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody className="mt-4">
                    {CartArr?.map((item, index) => (
                      <tr key={item.id} className="mt-2">
                        <td className="w-50">
                          <Link>
                            <div
                              id={styles.serviceItem}
                              className={`${styles1.servicesItem} ${styles.serviceItem}`}
                              key={item.id}
                            >
                              <ServiceItem service={item} type="cartItem" />
                            </div>
                          </Link>
                        </td>
                        <td
                          className={lang === "ar" ? `text-end` : `text-start`}
                        >
                          <p className={styles.priceOf}>{item.quantity}</p>
                        </td>
                        <td
                          className={
                            lang === "ar"
                              ? `text-end`
                              : `text-start ${
                                  lang === "ar" ? `text-end` : `text-start`
                                }`
                          }
                        >
                          <p className={styles.priceOf}>{item.price} $</p>
                        </td>
                        <td
                          className={`${styles.deleteTd} ${
                            lang === "ar" ? `text-end` : `text-start`
                          }`}
                          onClick={() => handleDelete(item.id)}
                        >
                          <MdOutlineDeleteOutline
                            onClick={() => handleDelete(item.id)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Col>
              <Col md={12}>
                <div>
                  <p className={styles.Total} style={{ fontSize: "1.1rem" }}>
                    Cart Price {TotalPrice} $
                  </p>

                  {CartArr?.length > 0 && (
                    <input
                      type="submit"
                      value={"Check Out"}
                      className="main_btninput"
                    />
                  )}
                </div>
              </Col>
              <Col md={12}>
                <div className="row text-center">
                  <div className="col-6">
                    <input
                      value={discount}
                      onChange={(e) => {
                        setCartDiscount(e.target.value);
                      }}
                      placeholder="Enter Coupon"
                      type="text"
                    />
                  </div>
                  <div className="col-6">
                    {" "}
                    <button
                      className="main_btn"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(GetCpupon(discount));
                      }}
                    >
                      Add coupon
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
          {DisplayShipment && (
  <form action="" onSubmit={onSubmit}>
    <div className="mb-3">
      <label htmlFor="countrySelect" className="form-label">
        Select Country:
      </label>
      <select
        id="countrySelect"
        className="form-select"
        onChange={(e) => handleCountryChange(e)}
        required
      >
        <option value="" >Choose...</option>
        {Countries.map((country) => (
          <option key={country.id} value={country.id}>
            {country.name}
          </option>
        ))}
      </select>
    </div>
    <div className="totalPrice mb-3">
      <h6 className="text-secondary">
        Total Price : {ShipingPrice} $
      </h6>
    </div>
    <button type="submit" className="btn btn-primary">
      Pay now
    </button>
  </form>
)}
        </Container>
      </section>
    </Helmet>
  );
};

export default Cart;
